import React from 'react';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {CheckoutSlot} from '../CheckoutSlot';
import {SlotId} from '../../../domain/utils/slotId';
import {StoreNameAndLogo} from '../../../common/components/StoreNameAndLogo/StoreNameAndLogo';

export enum HeaderDataHooks {
  ContinueShoppingButton = 'HeaderDataHooks.ContinueShoppingButton',
}

export const Header = ({dataHook}: {dataHook: string}) => {
  const {isOneColumnView} = useControllerProps();
  const localKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutSettings, checkoutContent},
    navigationStore: {continueShoppingUrl, clickOnContinueShopping, isContinueShoppingEnabled},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const addSlotToCheckout = experiments.enabled(SPECS.AddSlotToCheckout);
  const shouldOverrideContent = experiments.enabled(SPECS.UseCheckoutContentOverride);
  const continueShoppingText = shouldOverrideContent
    ? checkoutContent?.continueShoppingLink ?? localKeys.checkout.continue_shopping()
    : localKeys.checkout.continue_shopping();

  return (
    <header className={classes.header}>
      <div className={st(classes.innerHeader)} data-hook={dataHook}>
        <StoreNameAndLogo
          continueShoppingUrl={continueShoppingUrl}
          clickOnContinueShopping={clickOnContinueShopping}
          logoUrl={checkoutSettings.logoUrl}
          storeName={checkoutSettings.storeName}
        />
        {isContinueShoppingEnabled && (
          <TextButton
            as={'a'}
            className={classes.continueShoppingButton}
            data-hook={HeaderDataHooks.ContinueShoppingButton}
            href={continueShoppingUrl}
            onClick={() => clickOnContinueShopping()}
            priority={TextButtonPriority.link}>
            {continueShoppingText}
          </TextButton>
        )}
      </div>
      <div className={classes.divider} />
      {
        /* istanbul ignore next: test slot */ addSlotToCheckout && (
          <CheckoutSlot
            className={isOneColumnView ? classes.headerCheckoutSlotMobile : classes.headerCheckoutSlot}
            slotId={SlotId.HeaderCheckoutSlot}
          />
        )
      }
    </header>
  );
};
